import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

/* COMPONENTS */
import VisitorLog from "./components/VisitorLog"; 

/* UTILITIES */
import StorageContext from "./utils/contexts/StorageContext";
import { useLocalStorage, useSessionStorage } from "./utils/useStorage";

/* PAGES */
import Main from "./pages/Main";
import EmailSignup from "./pages/EmailSignup";
import VerifyEmail from "./pages/VerifyEmail";
import Login from "./pages/Login";
import RequestResetPassword from "./pages/RequestResetPassword";
import ResetPassword from "./pages/ResetPassword";

import UnityDownloadV1 from "./pages/UnityDownloadV1";
import UnityDownloadV2 from "./pages/UnityDownloadV2";
import UnityDownloadV3 from "./pages/UnityDownloadV3";

import WebPaleozoicEra from "./pages/WebPaleozoicEra";
import WebMesozoicEra from "./pages/WebMesozoicEra";
import WebCenozoicEra from "./pages/WebCenozoicEra";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnmount: true,
      refetchOnReconnect: true,
      retry: true,
      enabled: true,
    },
  },
});

const App = () => {
  const [sessionLoggedInAt, setSessionLoggedInAt] = useSessionStorage("loggedInAt", "");
  const [localLanguage, setLocalLanguage] = useLocalStorage("language", "korean");
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);  
  const [registeredAccountId, setRegisteredAccountId] = useState();
  const [isUnityDownloaded, setIsUnityDownloaded] = useState(false);

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <StorageContext.Provider value={{  

          localLanguage, setLocalLanguage, 
          sessionLoggedInAt, setSessionLoggedInAt,

          isLoggedIn, setIsLoggedIn,
          registeredAccountId, setRegisteredAccountId,
          isUnityDownloaded, setIsUnityDownloaded,

        }}>
          <Router>
            <VisitorLog />
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/email-signup" element={<EmailSignup />} />
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route path="/login" element={<Login />} />
              <Route path="/request-reset-password" element={<RequestResetPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />

              <Route path="/metaverse-download" element={<UnityDownloadV1 />} />
              <Route path="/metaverse-download-zip" element={<UnityDownloadV2 />} />
              <Route path="/metaverse-download-google" element={<UnityDownloadV3 />} />

              <Route path="/paleozoic-era" element={<WebPaleozoicEra />} />
              <Route path="/mesozoic-era" element={<WebMesozoicEra />} />
              <Route path="/cenozoic-era" element={<WebCenozoicEra />} />

              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Router>
        </StorageContext.Provider> 
      </QueryClientProvider>
    </div>
  );
};

export default App;