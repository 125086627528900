import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import bg from "./img/auth-bg.jpg";
import ModalContext from "../../utils/contexts/ModalContext";
import TopNavBar from "../../components/TopNavBar";
import { VerifyEmailSuccessModal, VerifyEmailFailModal } from "../../components/Modal";
import { postWebVerifyEmailUserAPI } from "../../utils/visitorLogAxiosAPI";

const VerifyEmail = () => {

  const search = useLocation().search;
  const emailQueryParam = new URLSearchParams(search).get("email");

  const [isResponseSuccess, setIsResponseSuccess] = useState(false);
  const [isVerifyEmailModalRequired, setIsVerifyEmailModalRequired] = useState(false);

  const { mutate, isLoading } = useMutation(postWebVerifyEmailUserAPI, {
    onSuccess: (data) => {
        console.log("useMutation Success response data: ", data?.data);
        if (data?.data?.ok === true) { 
          setIsResponseSuccess(true); 
          setIsVerifyEmailModalRequired(true);
        }
    },
    onError: (error, variables) => {
        console.log("useMutation Error response data: ", error?.response?.data);
        if (error?.response?.data?.ok === false) { 
          setIsResponseSuccess(false); 
          setIsVerifyEmailModalRequired(true);
        }
    },
  });

    const { register, handleSubmit, formState: { errors } } = useForm({ // getValues, watch, , setError, reset, setError, setValue , clearErrors
        mode: "onBlur", // mode: "all", "onBlur", "onChange", "onTouched"
        defaultValues: {
          email: "",
          password: "",
        },
    });

    const onValid = (submittedFormData) => {
      const { verifyEmailToken } = submittedFormData;

      if (!emailQueryParam || isLoading || !verifyEmailToken) return;

      mutate({
        email: emailQueryParam,
        verifyEmailToken
      });
    };
    
    const onInvalid = (errors) => {
        console.log("onInvalid errors: ", errors);
        console.log("This is onInvalid!");
    };

    return (
      <div>
        <TopNavBar />
        <div className="flex w-screen h-screen justify-center items-center">
            <img src={bg} alt="auth-bg-03" className="fixed w-full h-full object-cover" />
            <div className="w-screen inset-0 max-w-sm lg:w-96 z-10 bg-gray-300 bg-opacity-70 p-10 rounded-xl">
                
                <h2 className="text-center text-4xl font-thin tracking-tight text-gray-900">이메일 본인 인증</h2>
                {/* Welcome to Seodaemun Metaverse Museum */}
                
                <form 
                  onSubmit={handleSubmit(onValid, onInvalid)}
                  className="space-y-6 mt-10"
                  spellCheck="false"
                >

                {/* (1). VERIFY EMAIL ADDRESS */}
                <div>
                  <label 
                      htmlFor="verifyEmailToken" 
                      className="block text-lg font-extralight text-gray-800"
                  >
                    이메일 인증번호
                  </label>
                    <input
                        id="verifyEmailToken"
                        name="verifyEmailToken"
                        label="verifyEmailToken"
                        type="text"
                        placeholder="ex. 123456"

                        {...register("verifyEmailToken", {
                            required: "이메일로 발송된 인증번호는 필수입니다.",
                            validate: {
                              // notContains4321: (value) => !value.includes("4321") || "Sorry, a valid resetPasswordToken should NOT contain '4321'.", // if return false -> validation error!
                              noWhiteSpace: (value) => value === value.trim() || "띄어쓰기를 없애주세요!",
                              // positive: v => parseInt(v) > 0,
                              // lessThanTen: v => parseInt(v) < 10,
                            },
                        })}
                        className="text-gray-600 mt-2 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                    />
                    <p className="mt-2 text-md font-extralight text-red-600">{errors.verifyEmailToken?.message}</p>
                </div>

                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-cyan-500 py-2 px-4 text-lg font-light text-white shadow-sm hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-2"
                >
                  이메일 인증
                </button>
              </form>
            </div>

          <ModalContext.Provider value={{ isVerifyEmailModalRequired, setIsVerifyEmailModalRequired }}>
            {isResponseSuccess && <VerifyEmailSuccessModal message="이메일 본인 인증이 성공했습니다!" moveToPage="/login" /> }
            {!isResponseSuccess && <VerifyEmailFailModal message="이메일 본인 인증이 실패했습니다!" /> }
          </ModalContext.Provider>
        </div>
      </div>
    )
};

export default VerifyEmail;
  