import axios from "axios";

export const getWebVisitorSessionNetworkAPI = async () => {
    return axios.get(`${process.env.REACT_APP_VISITOR_NEWORK_DETECT_API_ENDPOINT}`);
}; 
  
export const postWebVerifyEmailUserAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + "/api/v1/user/verify-email";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        data,
    });
};

export const postWebRequestResetPasswordUserAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + "/api/v1/user/create-reset-password-token";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        data,
    });
};

export const postWebResetPasswordUserAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + "/api/v1/user/reset-password";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        data,
    });
};




export const postWebVisitorLocalLocalIdAPI = (data) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + "/api/v1/web/local/update-local-id";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
}; 
  
  
export const postWebVisitorLocalSessionsAPI = (data) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + "/api/v1/web/local/update-sessions";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};
  
  
// export const postWebVisitorLocalVisitedDateAPI = (data) => {
//     const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + "/api/v1/web/local/update-visited-dates";

//     return axios(`${API_ENDPOINT}`, {
//         method: "POST",
//         headers: {
//         "Content-Type": "application/json", 
//         },
//         data,
//     });
// };
  
  
export const postWebVisitorLocalReferrerAPI = (data) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + "/api/v1/web/local/update-referrer";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};


export const postWebVisitorLocalIsUnityDownloadedAPI = (data) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + "/api/v1/web/local/update-is-unity-downloaded";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};
  
  
export const postWebVisitorLocalUsersAPI = (data) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + "/api/v1/web/local/update-users";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};
  
  
export const postWebVisitorSessionSessionIdAPI = (data) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + "/api/v1/web/session/update-session-id";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};
  
export const postWebVisitorSessionNetworkAPI = (data) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + "/api/v1/web/session/update-network";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};
  

export const postWebVisitorSessionDeviceAPI = (data) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + "/api/v1/web/session/update-device";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};
  

export const postWebVisitorSessionCurrentVisitAPI = (data) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + "/api/v1/web/session/update-current-visit";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};
  
export const postWebVisitorSessionPreviousVisitAPI = (data) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + "/api/v1/web/session/update-previous-visit";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};
  

export const postWebVisitorSessionReferrerAPI = (data) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + "/api/v1/web/session/update-referrer";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};
  

export const postWebVisitorSessionUsersAPI = (data) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + "/api/v1/web/session/update-users";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json", 
        },
        data,
    });
};




export const postWebLoginUserAPI = (data) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + "/api/v1/user/login";

    return axios(`${API_ENDPOINT}`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        data,
    });
}; 




export const postWebSignupUserAPI = (data) => {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT + "/api/v1/user/signup";
  
    return axios(`${API_ENDPOINT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });
  };