import { useState, useEffect } from "react";

export const useLocalStorage = (key, initialState) => {
  const [state, setState] = useState(() => JSON.parse(window.localStorage.getItem(key)) || initialState);

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};

export const useSessionStorage = (key, initialState) => {
    const [state, setState] = useState(() => JSON.parse(window.sessionStorage.getItem(key)) || initialState);
  
    useEffect(() => {
      window.sessionStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
  
    return [state, setState];
};