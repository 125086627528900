import React, { useContext } from "react"; // { useState, useEffect }
import StorageContext from "../../utils/contexts/StorageContext";
import bgUnityTeaser from "./videos/bg-video-unity-teaser-low.mp4";
import bgVideoPaleozoic from "./videos/bg-video-paleozoic-low.mp4";
import bgVideoMesozoic from "./videos/bg-video-mesozoic-low.mp4";
import bgVideoCenozoic from "./videos/bg-video-cenozoic-high.mp4";

import TopNavBar from "../../components/TopNavBar";
import Slider from "../../components/Slider";
import { isDesktop } from "react-device-detect";

// import { useNavigate } from 'react-router-dom';
// import { clsx } from '../../libs/mergeClassNames';

// import { useLocation } from "react-router-dom";

const Main = () => {
  const storageContext = useContext(StorageContext); 

  // const search = useLocation().search;
  // const emailQueryParam = new URLSearchParams(search).get("email");

  return (
    <div>
      <TopNavBar />

      <div className="fixed w-screen bottom-2 z-50 text-gray-200 text-center text-[0.6rem]">
        {storageContext.localLanguage !== "english" ? "스크롤" : "SCROLL"} &#8595;
      </div>

      <main className="max-h-screen overflow-y-scroll snap snap-y snap-mandatory">
          <section className="w-full h-screen snap-start">
            <Slider 
              id={1} 
              language={storageContext.localLanguage !== "english" ? "Korean" : "English"} 
              title={storageContext.localLanguage !== "english" ? "서대문자연사박물관" : "SEODAEMUN"}
              // subtitle={language === true ? "A Metaverse Museum" : "메타버스 전시공간"} 
              href="/"
              videoSrc={bgUnityTeaser}
              // setVideoLoaded={setVideoLoaded} 
            />

          </section>

          {!isDesktop &&
          <section className="w-full h-screen snap-start">
            <Slider 
              id={3} 
              language={storageContext.localLanguage !== "english" ? "Korean" : "English"} 
              title={storageContext.localLanguage !== "english" ? "고생대 시대" : "Paleozoic Era"} 
              subtitle="3D Web Metaverse"
              href="/paleozoic-era"
              videoSrc={bgVideoPaleozoic}
              // setVideoLoaded={setVideoLoaded} 
            />
          </section>}

          {!isDesktop &&
          <section className="w-full h-screen snap-start">
            <Slider 
              id={4} 
              language={storageContext.localLanguage !== "english" ? "Korean" : "English"} 
              title={storageContext.localLanguage !== "english" ? "중생대 시대" : "Mesozoic Era"} 
              subtitle="3D Web Metaverse"
              href="/mesozoic-era"
              videoSrc={bgVideoMesozoic}
              // setVideoLoaded={setVideoLoaded} 
            />
          </section>}

          {!isDesktop &&
          <section className="w-full h-screen snap-start">
            <Slider 
              id={5} 
              language={storageContext.localLanguage !== "english" ? "Korean" : "English"} 
              title={storageContext.localLanguage !== "english" ? "신생대 시대" : "Cenozoic Era"} 
              subtitle="3D Web Metaverse"
              href="/cenozoic-era"
              videoSrc={bgVideoCenozoic}
              // setVideoLoaded={setVideoLoaded} 
            />
          </section>}

      </main>
    </div>
  );
};

export default Main;
