import { useState, useEffect, useContext } from "react"; // useState, 
import { useLocation } from "react-router-dom";
import { useMutation } from "react-query"; // useQuery, 
import { v4 as uuidv4 } from "uuid";
import { 
  getUA,
  isMobile,
  isTablet,
  isDesktop,	
  isWindows,
  isMacOs,
  isAndroid,
  isIOS,
  isWinPhone,
  browserName,
  osName,
  osVersion,
  deviceType,	

  isChrome,
  isFirefox,
  isSafari,
  isOpera,
  isIE,
  isEdge,
  isYandex,
  isChromium,
  isMobileSafari,
  isSamsungBrowser,

  isSmartTV,
  isWearable,
  isConsole,
  isEmbedded,
  isElectron,
  isEdgeChromium,

  fullBrowserVersion,
  mobileVendor,
  mobileModel,
} from "react-device-detect";
import {
  getWebVisitorSessionNetworkAPI,
  postWebVisitorLocalLocalIdAPI, postWebVisitorLocalSessionsAPI, postWebVisitorLocalReferrerAPI, postWebVisitorLocalUsersAPI, postWebVisitorLocalIsUnityDownloadedAPI, // postWebVisitorLocalVisitedDateAPI
  postWebVisitorSessionSessionIdAPI, postWebVisitorSessionNetworkAPI, postWebVisitorSessionDeviceAPI, postWebVisitorSessionCurrentVisitAPI, postWebVisitorSessionPreviousVisitAPI, postWebVisitorSessionReferrerAPI, postWebVisitorSessionUsersAPI, // postWebVisitorSessionAccountIdAPI
} from "../../utils/visitorLogAxiosAPI";
import { encryptWithAES, decryptWithAES } from "../../utils/crypto";
import StorageContext from "../../utils/contexts/StorageContext";
import { useLocalStorage, useSessionStorage } from "../../utils/useStorage";
// import { isEmpty } from "../../libs/client/isEmpty";


const localUUID = uuidv4();
const sessionUUID = uuidv4();
// const now = new Date().toISOString();

const VisitorLog = () => {
  const storageContext = useContext(StorageContext); 
  const location = useLocation(); 

  const [sessionId, setSessionId] = useSessionStorage("sid", ""); // sessionId
  const [localId, setLocalId] = useLocalStorage("lid", ""); // localId

  const [visitorSessionId, setVisitorSessionId] = useState("");
  const [visitorLocalId, setVisitorLocalId] = useState("");

  const [isVisitorSessionIdUpdated, setIsVisitorSessionIdUpdated] = useState(false);
  // const [visitorPreviousVisit, setVisitorPreviousVisit] = useSessionStorage("pvst", ""); // previous visit for <visits>

  const [visitorNetwork, setVisitorNetwork] = useState("");
  const [visitorDevice, setVisitorDevice] = useState("");
  const [visitorRegisteredAccountId, setVisitorRegisteredAccountId] = useState("");
  const [visitorLoggedInAccountId, setVisitorLoggedInAccountId] = useState("");
  const [visitorCurrentVisit, setVisitorCurrentVisit] = useState("");
  const [visitorPreviousVisit, setVisitorPreviousVisit] = useState("");
  const [visitorReferrer, setVisitorReferrer] = useState("");
  const [visitorIsUnityDownloaded, setVisitorIsUnityDownloaded] = useState(false);


const { data: networkData, mutate: fetchNeworkData } = useMutation(getWebVisitorSessionNetworkAPI, { // isLoading: isNetworkDataLoading, 
  onSuccess: (data, variables, context) => {
    console.log("✅ [fetchNeworkData] is successful!");
    const location = data?.data?.location;
    const updateNetwork = decryptWithAES(location);
    
    if (!updateNetwork) return;
    setVisitorNetwork({ ...updateNetwork });
  },
  onError: (error, variables, context) => {
    console.log("❌ [fetchNeworkData] Error: ", error);
  },
});



/* 

  POST LOCAL DATA <WebVisitorLocal> 

*/

const { mutate: mutateLocalLocalId } = useMutation(postWebVisitorLocalLocalIdAPI, { // data: localLocalIdData, isLoading: isLocalLocalIdLoading, 
  onSuccess: (data, variables, context) => {
    console.log("✅ [mutateLocalLocalId] is successful!");
  },
  onError: (error, variables, context) => {
    console.log("❌ [mutateLocalLocalId] Error: ", error);
  },
});

const { mutate: mutateLocalSessions } = useMutation(postWebVisitorLocalSessionsAPI, { // data: localSessionsData, isLoading: isLocalSessionsLoading, 
  onSuccess: (data, variables, context) => {
    console.log("✅ [mutateLocalSessions] is successful!");
  },
  onError: (error, variables, context) => {
    console.log("❌ [mutateLocalSessions] Error: ", error);
  },
});

const { mutate: mutateLocalReferrer } = useMutation(postWebVisitorLocalReferrerAPI, { // data: localReferrerData, isLoading: isLocalReferrerLoading, 
  onSuccess: (data, variables, context) => {
    console.log("✅ [mutateLocalReferrer] is successful!");
  },
  onError: (error, variables, context) => {
    console.log("❌ [mutateLocalReferrer] Error: ", error);
  },
});

const { mutate: mutateLocalIsUnityDownloaded } = useMutation(postWebVisitorLocalIsUnityDownloadedAPI, { // data: localIsUnityDownloadedData, isLoading: isLocalIsUnityDownloadedLoading, 
  onSuccess: (data, variables, context) => {
    console.log("✅ [mutateLocalIsUnityDownloaded] is successful!");
  },
  onError: (error, variables, context) => {
    console.log("❌ [mutateLocalIsUnityDownloaded] Error: ", error);
  },
});

const { mutate: mutateLocalAccountId } = useMutation(postWebVisitorLocalUsersAPI, { // data: localAccountIdData, isLoading: isLocalAccountIdLoading, 
  onSuccess: (data, variables, context) => {
    console.log("✅ [mutateLocalAccountId] is successful!");
  },
  onError: (error, variables, context) => {
    console.log("❌ [mutateLocalAccountId] Error: ", error);
  },
});




/* 

  POST SESSION DATA <WebVisitorSession> 

*/

const { mutate: mutateSessionSessionId } = useMutation(postWebVisitorSessionSessionIdAPI, { // data: sessionSessionIdData, isLoading: isSessionSessionIdLoading, 
  onSuccess: (data, variables, context) => {
    console.log("✅ [mutateSessionSessionId] is successful!");
    if (!isVisitorSessionIdUpdated) setIsVisitorSessionIdUpdated(true);
  },
  onError: (error, variables, context) => {
    console.log("❌ [mutateSessionSessionId] Error: ", error);
  },
});


const { mutate: mutateSessionNetwork } = useMutation(postWebVisitorSessionNetworkAPI, { // data: sessionNetworkData, isLoading: isSessionNetworkLoading, 
  onSuccess: (data, variables, context) => {
    console.log("✅ [mutateSessionNetwork] is successful!");
    if (!isVisitorSessionIdUpdated) setIsVisitorSessionIdUpdated(true);
  },
  onError: (error, variables, context) => {
    console.log("❌ [mutateSessionNetwork] Error: ", error);
  },
});

const { mutate: mutateSessionDevice } = useMutation(postWebVisitorSessionDeviceAPI, { // data: sessionDeviceData, isLoading: isSessionDeviceLoading, 
  onSuccess: (data, variables, context) => {
    console.log("✅ [mutateSessionDevice] is successful!");
    if (!isVisitorSessionIdUpdated) setIsVisitorSessionIdUpdated(true);
  },
  onError: (error, variables, context) => {
    console.log("❌ [mutateSessionDevice] Error: ", error);
  },
});

const { mutate: mutateSessionCurrentVisit } = useMutation(postWebVisitorSessionCurrentVisitAPI, { // data: sessionCurrentVisitData, isLoading: isSessionCurrentVisitLoading, 
  onSuccess: (data, variables, context) => {
    console.log("✅ [mutateSessionCurrentVisit] is successful!");
    if (!isVisitorSessionIdUpdated) setIsVisitorSessionIdUpdated(true);
  },
  onError: (error, variables, context) => {
    console.log("❌ [mutateSessionCurrentVisit] Error: ", error);
  },
});

const { mutate: mutateSessionPreviousVisit } = useMutation(postWebVisitorSessionPreviousVisitAPI, { // data: sessionPreviousVisitData, isLoading: isSessionPreviousVisitLoading, 
  onSuccess: (data, variables, context) => {
    console.log("✅ [mutateSessionPreviousVisit] is successful!");
    if (!isVisitorSessionIdUpdated) setIsVisitorSessionIdUpdated(true);
  },
  onError: (error, variables, context) => {
    console.log("❌ [mutateSessionPreviousVisit] Error: ", error);
  },
});

const { mutate: mutateSessionReferrer } = useMutation(postWebVisitorSessionReferrerAPI, { // data: sessionReferrerData, isLoading: isSessionReferrerLoading, 
  onSuccess: (data, variables, context) => {
    console.log("✅ [mutateSessionReferrer] is successful!");
    if (!isVisitorSessionIdUpdated) setIsVisitorSessionIdUpdated(true);
  },
  onError: (error, variables, context) => {
    console.log("❌ [mutateSessionReferrer] Error: ", error);
  },
});

const { mutate: mutateSessionAccountId } = useMutation(postWebVisitorSessionUsersAPI, { // data: sessionAccountIdData, isLoading: isSessionAccountIdLoading, 
  onSuccess: (data, variables, context) => {
    console.log("✅ [mutateSessionAccountId] is successful!");
  },
  onError: (error, variables, context) => {
    console.log("❌ [mutateSessionAccountId] Error: ", error);
  },
});


useEffect(() => {
  if (!localId) {

    const updateData = { localId: localUUID };
    setLocalId(updateData);
  }
}, [localId, setLocalId]);


useEffect(() => {
  if (!sessionId) {

    const updateData = { sessionId: sessionUUID };
    setSessionId(updateData);
  }
}, [sessionId, setSessionId]);


useEffect(() => {
  if (localId && !visitorLocalId) {

    setVisitorLocalId(localId)
  }
}, [localId, visitorLocalId, setVisitorLocalId]);

useEffect(() => {
  if (sessionId && !visitorSessionId) {
    setVisitorSessionId(sessionId);
  }
}, [sessionId, visitorSessionId, setVisitorSessionId]);


useEffect(() => {
  if (!visitorReferrer) {

    const referrer = window.document.referrer; // "www.google.com"
    if (!referrer) return;
    const updateData = { referrer };
    setVisitorReferrer(updateData);
  }
}, [visitorReferrer]);



useEffect(() => {

  const decryptedSessionLoggedInAt = decryptWithAES(storageContext?.sessionLoggedInAt);
  if (!decryptedSessionLoggedInAt) return;

  const loggedInAccountId = decryptedSessionLoggedInAt?.accountId;
  if (!loggedInAccountId || loggedInAccountId === visitorLoggedInAccountId?.accountId) return;

  setVisitorLoggedInAccountId({ accountId: loggedInAccountId });
}, [visitorLoggedInAccountId, setVisitorLoggedInAccountId, storageContext]);


useEffect(() => {
  if (storageContext?.registeredAccountId && visitorRegisteredAccountId?.accountId !== storageContext?.registeredAccountId) {

  setVisitorRegisteredAccountId({ accountId: storageContext?.registeredAccountId });
  }
}, [visitorRegisteredAccountId, setVisitorRegisteredAccountId, storageContext]);


useEffect(() => {
  if (storageContext?.isUnityDownloaded && visitorIsUnityDownloaded !== storageContext?.isUnityDownloaded) {
    setVisitorIsUnityDownloaded(true);
  }
}, [visitorIsUnityDownloaded, setVisitorIsUnityDownloaded, storageContext]);


///////////////////////////////////////////////////////////////////// 
///fetch web visitor's <network> data from the data center !!!
useEffect(() => {
  if (!networkData) {
    // if (isNetworkDataLoading) return;
    fetchNeworkData();
  }
}, [networkData, fetchNeworkData]);
  ///////////////////////////////////////////////////////////////////// 


useEffect(() => {
  if (!visitorDevice) {

    const updateDevice = {  
      getUA, 
      isMobile, isTablet, isDesktop, isWindows, isMacOs, 
      isAndroid, isIOS, isWinPhone,
      browserName, osName, osVersion, deviceType,	
    
      isChrome, isFirefox, isSafari, isOpera, isIE, isEdge,
      isYandex, isChromium, isMobileSafari, isSamsungBrowser,
    
      isSmartTV, isWearable, isConsole, isEmbedded,
      isElectron, isEdgeChromium,
    
      fullBrowserVersion, mobileVendor, mobileModel,
    };

    setVisitorDevice({ ...updateDevice });
  }
}, [visitorDevice, setVisitorDevice]);


useEffect(() => {
  if (visitorCurrentVisit && location.pathname && location.pathname !== visitorCurrentVisit?.path) {

    const currentVisitNow = new Date().toISOString();
    const updatePreviousVisit = { path: visitorCurrentVisit?.path, visitedAt: visitorCurrentVisit?.visitedAt, exitedAt: currentVisitNow };
    setVisitorPreviousVisit(updatePreviousVisit);

    const updateCurrentVisit = { path: location.pathname, visitedAt: currentVisitNow };
    setVisitorCurrentVisit(updateCurrentVisit);

  }
  else if (!visitorCurrentVisit && location.pathname) {

    const currentVisitNow = new Date().toISOString();
    const updateCurrentVisit = { path: location.pathname, visitedAt: currentVisitNow };
    setVisitorCurrentVisit(updateCurrentVisit);

  }
}, [visitorCurrentVisit, setVisitorCurrentVisit, setVisitorPreviousVisit, location.pathname]);



useEffect(() => {
  // if (location.pathname) console.log("CHECK pathname: ", location.pathname);
  if (visitorLocalId && visitorSessionId && visitorNetwork && visitorDevice && visitorCurrentVisit) {
    console.log("---------------------------------------------------------------------------------------");
    console.log("CHECK visitorLocalId: ", visitorLocalId);
    console.log("CHECK visitorSessionId: ", visitorSessionId);
    console.log("CHECK visitorNetwork: ", visitorNetwork);
    console.log("CHECK visitorDevice: ", visitorDevice);
    console.log("CHECK visitorCurrentVisit: ", visitorCurrentVisit);
    console.log("CHECK visitorRegisteredAccountId: ", visitorRegisteredAccountId);
    console.log("CHECK visitorLoggedInAccountId: ", visitorLoggedInAccountId);
    console.log("CHECK visitorPreviousVisit: ", visitorPreviousVisit);
    console.log("CHECK visitorIsUnityDownloaded: ", visitorIsUnityDownloaded);
    console.log("---------------------------------------------------------------------------------------");
  }
}, [
  visitorNetwork, visitorDevice,
  visitorCurrentVisit, visitorPreviousVisit, 
  visitorLocalId,
  visitorSessionId,
  visitorRegisteredAccountId, visitorLoggedInAccountId,
  visitorIsUnityDownloaded,
  // location.pathname,
]);




/* 

  <WebVisitorLocal> useMutation

*/

useEffect(() => {
  if (visitorLocalId?.localId) {
    
    // if (isLocalLocalIdLoading) return;
    const updateData = { update: { ...visitorLocalId } };
    mutateLocalLocalId(updateData); 
  }
}, [visitorLocalId, mutateLocalLocalId]); 


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// <sessions> 업데이트 할 때, 이미 <sessionId>가 DB에 등록되어 있어야 함!
useEffect(() => {
  if (visitorLocalId && visitorSessionId && isVisitorSessionIdUpdated) { // only when isVisitorSessionIdUpdated

    // if (isLocalSessionsLoading) return;
    const updateData = { ...visitorLocalId, update: { ...visitorSessionId } };
    mutateLocalSessions(updateData); 
  }
}, [visitorLocalId, visitorSessionId, mutateLocalSessions, isVisitorSessionIdUpdated]); 
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


useEffect(() => { 
  if (visitorLocalId && visitorReferrer) {
    
    // if (isLocalReferrerLoading) return;
    const updateData = { ...visitorLocalId, update: { ...visitorReferrer } };
    mutateLocalReferrer(updateData);
  }
}, [visitorLocalId, visitorReferrer, mutateLocalReferrer]);


useEffect(() => {
  if (visitorLocalId && visitorLoggedInAccountId) {

    // if (isLocalAccountIdLoading) return;
    const updateData = { ...visitorLocalId, update: { ...visitorLoggedInAccountId } };
    mutateLocalAccountId(updateData);
  }
}, [visitorLocalId, visitorLoggedInAccountId, mutateLocalAccountId]);


useEffect(() => {
  if (visitorLocalId && visitorRegisteredAccountId) {
    
    // if (isLocalAccountIdLoading) return;
    const updateData = { ...visitorLocalId, update: { ...visitorRegisteredAccountId } };
    mutateLocalAccountId(updateData);
  }
}, [visitorLocalId, visitorRegisteredAccountId, mutateLocalAccountId]);


useEffect(() => {
  if (visitorLocalId && visitorIsUnityDownloaded) {

    // if (isLocalIsUnityDownloadedLoading) return;
    const updateData = { ...visitorLocalId, update: { isUnityDownloaded: true }};
    mutateLocalIsUnityDownloaded(updateData);
  }
}, [visitorLocalId, visitorIsUnityDownloaded, mutateLocalIsUnityDownloaded]);





/* 

  <WebVisitorSession> useMutation

*/

useEffect(() => {
  if (visitorSessionId?.sessionId) {
    
    // if (isSessionSessionIdLoading) return;
    const updateData = { update: { ...visitorSessionId } };
    mutateSessionSessionId(updateData);
  }
}, [visitorSessionId, mutateSessionSessionId]); 


useEffect(() => {
  if (visitorSessionId?.sessionId && visitorNetwork) {
    
    // if (isSessionNetworkLoading) return;
    const updateData = { ...visitorSessionId, update: { network: { ...visitorNetwork } } };
    mutateSessionNetwork(updateData);
  }
}, [visitorSessionId, visitorNetwork, mutateSessionNetwork]);


useEffect(() => {
  if (visitorSessionId?.sessionId && visitorDevice) {
    
    // if (isSessionDeviceLoading) return;
    const updateData = { ...visitorSessionId, update: { device: { ...visitorDevice } } };
    mutateSessionDevice(updateData);
  }
}, [visitorSessionId, visitorDevice, mutateSessionDevice]);


useEffect(() => {
  if (visitorSessionId?.sessionId && visitorCurrentVisit) {
    
    // if (isSessionCurrentVisitLoading) return;
    const updateData = { ...visitorSessionId, update: { currentVisit: { ...visitorCurrentVisit } } };
    mutateSessionCurrentVisit(updateData);
  }
}, [visitorSessionId, visitorCurrentVisit, mutateSessionCurrentVisit]); 


useEffect(() => {
  if (visitorSessionId?.sessionId && visitorPreviousVisit) {

    // if (isSessionPreviousVisitLoading) return;
    const { path, visitedAt, exitedAt } = visitorPreviousVisit;
    if (!path || !visitedAt || !exitedAt) return;

    const updateData = { ...visitorSessionId, update: { previousVisit: { ...visitorPreviousVisit } } };
    mutateSessionPreviousVisit(updateData);
  }
}, [visitorSessionId, visitorPreviousVisit, mutateSessionPreviousVisit]);


useEffect(() => {
  if (visitorSessionId?.sessionId && visitorReferrer) {

    // if (isSessionReferrerLoading) return;
    const updateData = { ...visitorSessionId, update: { ...visitorReferrer } };
    mutateSessionReferrer(updateData);
  }
}, [visitorSessionId, visitorReferrer, mutateSessionReferrer]);


useEffect(() => {
  if (visitorSessionId?.sessionId && visitorLoggedInAccountId) { 
    
    // if (isSessionAccountIdLoading) return;
    const updateData = { ...visitorSessionId, update: { ...visitorLoggedInAccountId } };
    mutateSessionAccountId(updateData);
  }
}, [visitorSessionId, visitorLoggedInAccountId, mutateSessionAccountId]);


useEffect(() => {
  if (visitorSessionId?.sessionId && visitorRegisteredAccountId) {
    
    // if (isSessionAccountIdLoading) return;
    const updateData = { ...visitorSessionId, update: { ...visitorRegisteredAccountId } };
    mutateSessionAccountId(updateData);
  }
}, [visitorSessionId, visitorRegisteredAccountId, mutateSessionAccountId]);


  return;
};

export default VisitorLog;
