import React, { useContext } from "react";
import bg from "./img/premium-metaverse-museum-bg.png";
import StorageContext from "../../utils/contexts/StorageContext";
import TopNavBar from "../../components/TopNavBar";
import { isWindows } from "react-device-detect";
// import { clsx } from '../../libs/mergeClassNames';

const UnityDownloadV1 = () => {
  
  const storageContext = useContext(StorageContext); 

  return (
    <div>
      <TopNavBar /> 
      <div className="fixed w-screen h-screen bg-gray-900 bg-opacity-90 inset-0 flex justify-center items-center">
          <img
            src={bg}
            alt="bg"
            className="opacity-90 absolute object-cover w-screen h-screen inset-0 top-0 left-0"
          /> 

          <div className="relative text-gray-100">
            <p className="text-center text-xl sm:text-2xl md:text-3xl p-5 mb-5">"메타버스 박물관에 오신 것을 환영합니다"</p>
            <ul className="list-none text-sm sm:text-base md:text-lg [&>*]:p-3 p-3">
              <li>본 메타버스 박물관은 <span className="">'프리미엄 메타버스 박물관'</span>과 <span className="">'웹 메타버스 박물관'</span>을 함께 제공합니다.</li>
              <li>프리미엄 메타버스 박물관은 '프리미엄 메타버스 박물관 다운로드' 버튼을 클릭하시면 설치가 진행됩니다.</li>
              <li>웹 메타버스 박물관은 간단한 관람을 위한 것이며, 홈페이지에서 아래로 스크롤 다운을 해서 입장하시면 됩니다.</li>
              <li>현재 프리미엄 메타버스 박물관은 Windows PC 환경에서만 제공되며, 추후에 다른 환경에서도 관람할 수 있도록 개발중입니다.</li>
            </ul>
            <div className="flex justify-center items-center mt-14">
            
            {isWindows &&
            <a
              href={`${process.env.REACT_APP_SDM_UNITY_FILE_API_ENDPOINT}`}
              target="_blank"
              rel="noreferrer"
              // download
              onClick={() => storageContext.setIsUnityDownloaded(true)}
              className="text-base sm:text-lg md:text-xl rounded-lg p-5 animate-bounce bg-green-600 text-gray-200 bg-opacity-90 hover:bg-green-700 hover:bg-opacity-100"
            >
              <p>{storageContext.localLanguage !== "english" ? "프리미엄 메타버스 박물관 다운로드" : "Download Premium Metaverse Museum"}</p>
              <div className="mt-1 flex items-center justify-center"> 
                <p className="text-sm">{storageContext.localLanguage !== "english" ? "(업데이트: 2022-10-26)" : "(Update: 2022-10-26)"}</p>
              </div>
            </a>}

          </div>
          </div>
      </div>
    </div>
  );
};

export default UnityDownloadV1;
