import CryptoJS from "crypto-js";

export const encryptWithAES = (data, secretKey=`${process.env.REACT_APP_AES_ENCRYPTION_SECRET_KEY}`) => {
  try {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    return encrypted;
  }
  catch {
    return false;
  }
};

export const decryptWithAES = (encrypted, secretKey=`${process.env.REACT_APP_AES_ENCRYPTION_SECRET_KEY}`) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encrypted, secretKey);
    const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decrypted;
  }
  catch {
    return false;
  }
};