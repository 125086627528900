import { useEffect, useContext } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import StorageContext from "../../utils/contexts/StorageContext";
import { clsx } from "../../utils/mergeClassNames";
import { encryptWithAES, decryptWithAES } from "../../utils/crypto";
import { isDesktop } from "react-device-detect";

const TopNavBar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const storageContext = useContext(StorageContext); 

    useEffect(() => {
        if (storageContext.sessionLoggedInAt) {
            
            const decryptedSessionLoggedInAt = decryptWithAES(storageContext.sessionLoggedInAt);
            if (!decryptedSessionLoggedInAt) storageContext.setIsLoggedIn(false);

            const decryptedLoggedInAt = decryptedSessionLoggedInAt?.loggedInAt;
            const msDifference = new Date(new Date().toISOString()) - new Date(decryptedLoggedInAt);
            const minDifference = Math.floor(msDifference / (60 * 1000));
            
            if (!((typeof minDifference === "number") && (minDifference < 30))) storageContext.setIsLoggedIn(false);
            if ((typeof minDifference === "number") && (minDifference < 30)) storageContext.setIsLoggedIn(true);
        }
    }, [storageContext, location.pathname]);

    return (  
        <div className={clsx(
            "relative",
            location.pathname === "/login" ? "[&>div>button]:text-gray-600" : "[&>div>button]:text-gray-200"
        )}>
            <div className="absolute top-3 left-2 z-50">
                <button
                    className="rounded-lg py-3 px-[0.9rem] hover:bg-gray-300 hover:bg-opacity-70"
                    onClick={() => navigate("/")}
                >
                    {storageContext.localLanguage !== "english" ? "서대문자연사박물관" : "SEODAEMUN"}
                {/* <img 
                    className="w-16 h-8 rounded-md"
                    src="apple_touch_icon.png" 
                    alt="서대문자연사박물관" 
                /> */}
                </button>
            </div>
            <div className="absolute top-3 right-2 z-50">

                {!storageContext.isLoggedIn && isDesktop &&
                <button 
                    className="rounded-lg p-3 hover:bg-gray-300 hover:bg-opacity-70"
                    onClick={() => navigate("/login")}
                >
                    {storageContext.localLanguage !== "english" ? "로그인" : "Login"}
                </button>}

                {storageContext.isLoggedIn && isDesktop &&
                <button 
                    className="rounded-lg p-3 hover:bg-gray-300 hover:bg-opacity-70"
                    onClick={() => { 
                        const newLoggedOutAt = {
                            isLoggedIn: false
                        };

                        const encryptedLoggedOutAt = encryptWithAES(newLoggedOutAt);
                        if (!encryptedLoggedOutAt) return;

                        storageContext.setSessionLoggedInAt(encryptedLoggedOutAt);
                    }}
                >
                    {storageContext.localLanguage !== "english" ? "로그아웃" : "Logout"}
                </button>}

                <button 
                    className="rounded-lg pl-[0.9rem] pr-[0.9rem] py-3 text-gray-200 hover:bg-gray-300 hover:bg-opacity-70"
                    onClick={() => { 
                        if (storageContext.localLanguage === "korean") {
                            storageContext.setLocalLanguage("english");
                        }
                        else {
                            storageContext.setLocalLanguage("korean");
                        }
                    }}
                >
                    {storageContext.localLanguage !== "english" ? "KOR" : "ENG"}
                </button>
            </div>
      </div>
    )
};

export default TopNavBar;