import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form"; 
import { useMutation } from "react-query";
import bg from "./img/auth-bg.jpg";
import ModalContext from "../../utils/contexts/ModalContext";
import TopNavBar from "../../components/TopNavBar";
import { ResetPasswordSuccessModal, ResetPasswordFailModal } from "../../components/Modal";
import { postWebResetPasswordUserAPI } from "../../utils/visitorLogAxiosAPI";

const RequestResetPassword = () => {

  const search = useLocation().search;
  const emailQueryParam = new URLSearchParams(search).get("email");

  const [isResponseSuccess, setIsResponseSuccess] = useState(false);
  const [isResetPasswordModalRequired, setIsResetPasswordModalRequired] = useState(false);

  const { mutate, isLoading } = useMutation(postWebResetPasswordUserAPI, {
    onSuccess: (data) => {
        console.log("useMutation Success response data: ", data?.data);
        if (data?.data?.ok === true) { 
          setIsResponseSuccess(true); 
          setIsResetPasswordModalRequired(true);
        }
    },
    onError: (error, variables) => {
        console.log("useMutation Error response data: ", error?.response?.data);
        if (error?.response?.data?.ok === false) { 
          setIsResponseSuccess(false); 
          setIsResetPasswordModalRequired(true);
        }
    },
  });

    const { register, handleSubmit, formState: { errors } } = useForm({ // getValues, watch, , setError, reset, setError, setValue , clearErrors
        mode: "onBlur", // mode: "all", "onBlur", "onChange", "onTouched"
        defaultValues: {
          email: "",
          password: "",
        },
    });

    const onValid = (submittedFormData) => {
      const { resetPasswordToken, password } = submittedFormData;

      if (!emailQueryParam || isLoading || !resetPasswordToken || !password) return;

      mutate({
        email: emailQueryParam,
        resetPasswordToken,
        typedPassword: password,

      });
    };
    
    const onInvalid = (errors) => {
        console.log("onInvalid errors: ", errors);
        console.log("This is onInvalid!");
    };

    return (
      <div>
        <TopNavBar />
        <div className="flex w-screen h-screen justify-center items-center">
            <img src={bg} alt="auth-bg-03" className="fixed w-full h-full object-cover" />
            <div className="w-screen inset-0 max-w-sm lg:w-96 z-10 bg-gray-300 bg-opacity-70 p-10 rounded-xl">
                
                <h2 className="text-center text-4xl font-thin tracking-tight text-gray-900">새로운 비밀번호 설정</h2>
                {/* Welcome to Seodaemun Metaverse Museum */}
                
                <form 
                  onSubmit={handleSubmit(onValid, onInvalid)}
                  className="space-y-6 mt-10"
                  spellCheck="false"
                >

                {/* (1). <RESET PASSWORD VERIFICATION TOKEN> */}
                <div>
                  <label 
                      htmlFor="resetPasswordToken" 
                      className="block text-lg font-extralight text-gray-800"
                  >
                    이메일 인증번호
                  </label>
                    <input
                        id="resetPasswordToken"
                        name="resetPasswordToken"
                        label="resetPasswordToken"
                        type="text"
                        // autoComplete="password" // Note: The autocomplete attribute works with the following input types: [text, search, url, tel, email, password, datepickers, range, and color]
                        placeholder="ex. 123456"

                        {...register("resetPasswordToken", {
                            required: "이메일로 발송된 인증번호는 필수입니다.",
                            validate: {
                              // notContains4321: (value) => !value.includes("4321") || "Sorry, a valid resetPasswordToken should NOT contain '4321'.", // if return false -> validation error!
                              noWhiteSpace: (value) => value === value.trim() || "띄어쓰기를 없애주세요!",
                              // positive: v => parseInt(v) > 0,
                              // lessThanTen: v => parseInt(v) < 10,
                            },
                        })}
                        className="text-gray-600 mt-2 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                    />
                    <p className="mt-2 text-md font-extralight text-red-600">{errors.resetPasswordToken?.message}</p>
                </div>


                {/* (2). <NEW PASSWORD> */}
                <div>
                  <label 
                      htmlFor="password" 
                      className="block text-lg font-extralight text-gray-800"
                  >
                    새로운 비밀번호
                  </label>
                    <input
                        id="password"
                        name="password"
                        label="password"
                        type="password"
                        // autoComplete="password" // Note: The autocomplete attribute works with the following input types: [text, search, url, tel, email, password, datepickers, range, and color]
                        placeholder="ex. ******"

                        {...register("password", {
                            required: "비밀번호는 필수입니다.",
                            minLength: {
                                message: "비밀번호는 6자리 이상이여야 합니다.",
                                value: 3,
                            },
                            validate: {
                              notContains4321: (value) => !value.includes("4321") || "Sorry, a valid password should NOT contain '4321'.", // if return false -> validation error!
                              noWhiteSpace: (value) => value === value.trim() || "Sorry, a password should not contain any whitespace.",
                              // positive: v => parseInt(v) > 0,
                              // lessThanTen: v => parseInt(v) < 10,
                            },
                        })}
                        className="text-gray-600 mt-2 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                    />
                    <p className="mt-2 text-md font-extralight text-red-600">{errors.password?.message}</p>
                </div>


                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-cyan-500 py-2 px-4 text-lg font-light text-white shadow-sm hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-2"
                >
                  새로운 비밀번호 설정
                </button>
              </form>
            </div>

          <ModalContext.Provider value={{ isResetPasswordModalRequired, setIsResetPasswordModalRequired }}>
            {isResponseSuccess && <ResetPasswordSuccessModal message="새로운 비밀번호 설정이 성공했습니다!" moveToPage="/login" /> }
            {!isResponseSuccess && <ResetPasswordFailModal message="새로운 비밀번호 설정이 실패했습니다!" /> }
          </ModalContext.Provider>
        </div>
      </div>
    )
};

export default RequestResetPassword;
  