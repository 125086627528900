import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form"; // set, 
import { useMutation } from "react-query"; // useQuery, useQueryClient
import bg from "./img/auth-bg.jpg";
import StorageContext from "../../utils/contexts/StorageContext";
import ModalContext from "../../utils/contexts/ModalContext";
import TopNavBar from "../../components/TopNavBar";
import { SignupSuccessModal, SignupFailModal } from "../../components/Modal";
import { postWebSignupUserAPI } from "../../utils/visitorLogAxiosAPI";

const EmailSignup = () => {
  const storageContext = useContext(StorageContext); 
  const [accountId, setAccountId] = useState("");
  const [isSuccessUpdated, setIsSuccessUpdated] = useState(false);
  const [isResponseSuccess, setIsResponseSuccess] = useState(false);
  const [isSignupModalRequired, setIsSignupModalRequired] = useState(false);

  const { mutate, isLoading } = useMutation(postWebSignupUserAPI, { // data, 
    onSuccess: (data) => {
        console.log("useMutation Success response data: ", data?.data);
        if (data?.data?.ok === true) { 
          setIsResponseSuccess(true); 
          setIsSignupModalRequired(true);
        }
    },
    onError: (error, variables) => {
        console.log("useMutation Error response data: ", error?.response?.data);
        if (error?.response?.data?.ok === false) { 
          setIsResponseSuccess(false); 
          setIsSignupModalRequired(true);
        }
    },
  });

    const { register, handleSubmit, formState: { errors } } = useForm({ // getValues, watch, , setError, reset, setError, setValue , clearErrors
        mode: "onBlur", // mode: "all", "onBlur", "onChange", "onTouched"
        defaultValues: {
          email: "",
          password: "",
        },
    });

    const onValid = (submittedFormData) => {
        const { email, password } = submittedFormData;

        if (isLoading || !email || !password) return;
        setAccountId(email);

        mutate({
          email: email, // submittedEmail,
          typedPassword: password, //submittedPassword,
      });
    };
    
    const onInvalid = (errors) => {
        console.log("onInvalid errors: ", errors);
        console.log("This is onInvalid!");
    };

    useEffect(() => {
      if (!isSuccessUpdated && isResponseSuccess && accountId && storageContext.registeredAccountId !== accountId) {

        storageContext.setRegisteredAccountId(accountId);
        setIsSuccessUpdated(true);
      }
    }, [isSuccessUpdated, setIsSuccessUpdated, isResponseSuccess, storageContext, accountId]);

    return (
      <div>
        <TopNavBar />
        <div className="flex w-screen h-screen justify-center items-center">
            <img src={bg} alt="auth-bg-03" className="fixed w-full h-full object-cover" />
            <div className="w-screen inset-0 max-w-sm lg:w-96 z-10 bg-gray-300 bg-opacity-70 p-10 rounded-xl">
                
                <h2 className="text-center text-4xl font-thin tracking-tight text-gray-900">회원가입</h2>
                {/* Welcome to Seodaemun Metaverse Museum */}
                
                <form 
                  onSubmit={handleSubmit(onValid, onInvalid)}
                  className="space-y-6 mt-10"
                  spellCheck="false"
                >

                {/* (1). EMAIL ADDRESS */}
                <div>
                  <label 
                      htmlFor="email" 
                      className="block text-lg font-extralight text-gray-800"
                  >
                    이메일 주소
                  </label>
                    <input
                        id="email"
                        name="email"
                        label="email"
                        type="email"
                        autoComplete="email" // Note: The autocomplete attribute works with the following input types: [text, search, url, tel, email, password, datepickers, range, and color]
                        placeholder="ex. ******@gmail.com"

                        {...register("email", {
                            required: "이메일 주소는 필수입니다.",
                            minLength: {
                                message: "이메일 주소는 7자리 이상이여야 합니다.",
                                value: 7,
                            },
                            pattern: {
                              value:/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, 
                              message:"잘못된 이메일 형식입니다."
                            },
                            validate: {
                              notContainsAt: (value) => value.includes("@") || "Sorry, a valid email address should contain '@'.", // if return false -> validation error!
                              lowercase: (value) => value === value.toLowerCase() || "Sorry, an email address should be lowercase chars.", // if return false -> validation error!
                              noWhiteSpace: (value) => value === value.trim() || "Sorry, an email address should not contain any whitespace.",
                              // positive: v => parseInt(v) > 0,
                              // lessThanTen: v => parseInt(v) < 10,
                            },
                        })}
                        className="text-gray-600 mt-2 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                    />
                    <p className="mt-2 text-md font-extralight text-red-600">{errors.email?.message}</p>
                </div>

                {/* (2). PASSWORD */}
                <div>
                  <label 
                      htmlFor="password" 
                      className="block text-lg font-extralight text-gray-800"
                  >
                    비밀번호
                  </label>
                    <input
                        id="password"
                        name="password"
                        label="password"
                        type="password"
                        autoComplete="password" // Note: The autocomplete attribute works with the following input types: [text, search, url, tel, email, password, datepickers, range, and color]
                        placeholder="ex. ******"

                        {...register("password", {
                            required: "비밀번호는 필수입니다.",
                            minLength: {
                                message: "비밀번호는 6자리 이상이여야 합니다.",
                                value: 3,
                            },
                            validate: {
                              notContains4321: (value) => !value.includes("4321") || "Sorry, a valid password should NOT contain '4321'.", // if return false -> validation error!
                              noWhiteSpace: (value) => value === value.trim() || "Sorry, a password should not contain any whitespace.",
                              // positive: v => parseInt(v) > 0,
                              // lessThanTen: v => parseInt(v) < 10,
                            },
                        })}
                        className="text-gray-600 mt-2 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                    />
                    <p className="mt-2 text-md font-extralight text-red-600">{errors.password?.message}</p>
                </div>

                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-cyan-500 py-2 px-4 text-lg font-light text-white shadow-sm hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-2"
                >
                  회원가입
                </button>
              </form>
            </div>

          <ModalContext.Provider value={{ isSignupModalRequired, setIsSignupModalRequired }}>
            {isResponseSuccess && accountId && <SignupSuccessModal message="회원가입이 성공했습니다!" moveToPage={`/verify-email?email=${accountId}`} /> }
            {!isResponseSuccess && <SignupFailModal message="회원가입이 실패했습니다!" /> }
          </ModalContext.Provider>
        </div>
      </div>
    )
};

export default EmailSignup;
  